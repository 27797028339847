import { Observable } from "@apollo/client/utilities/observables/Observable";

export const updateAuthHeader = (previousContext: any, token?: string) => {
  return {
    ...previousContext,
    headers: {
      ...previousContext?.headers,
      authorization: token,
    },
  };
};

export const promiseToObservable = (promise: Promise<unknown>) =>
  new Observable((subscriber) => {
    promise.then(
      (value: unknown) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });
