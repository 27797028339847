import { Amplify } from "aws-amplify";
import {
  fetchAuthSession,
  signInWithRedirect,
  signOut,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { useEffect, useState, useCallback } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Location, useNavigate } from "react-router-dom";
import {
  AmplifyDevConfiguration,
  AmplifyQaConfiguration,
  AmplifyIntConfiguration,
  AmplifyProdConfiguration,
} from "../../amplify";

export interface UserInterface {
  id: string;
  exp: number;
  name: string;
}

interface CognitoUserSessionInterface {
  idToken: {
    jwtToken: string;
    payload: {
      "cognito:username": string;
      jti: string;
      exp: number;
    };
  };
}

const amplifyConfiguration =
  process.env.REACT_APP_ENV === "qa"
    ? AmplifyQaConfiguration
    : process.env.REACT_APP_ENV === "int"
    ? AmplifyIntConfiguration
    : process.env.REACT_APP_ENV === "prod"
    ? AmplifyProdConfiguration
    : AmplifyDevConfiguration;

Amplify.configure({ ...amplifyConfiguration });

export let inMemoryToken: string | undefined;

function getUserSession(): Promise<void | CognitoUserSessionInterface> {
  return fetchAuthSession()
    .then((session) => {
      if (session) {
        inMemoryToken = session.tokens?.idToken?.toString();

        if (inMemoryToken) {
          return {
            idToken: {
              jwtToken: inMemoryToken,
              payload: {
                "cognito:username":
                  session.tokens?.idToken?.payload["cognito:username"],
                jti: session.tokens?.idToken?.payload.jti,
                exp: session.tokens?.idToken?.payload.exp,
              },
            },
          } as CognitoUserSessionInterface;
        }

        return undefined;
      }
    })
    .catch(() => console.log("Not signed in"));
}

export function refreshToken(cb?: any) {
  fetchAuthSession()
    .then((session) => {
      if (session) {
        inMemoryToken = session.tokens?.idToken?.toString();

        const cognitoUserSession = {
          idToken: {
            jwtToken: inMemoryToken,
            payload: {
              "cognito:username":
                session.tokens?.idToken?.payload["cognito:username"],
              jti: session.tokens?.idToken?.payload.jti,
              exp: session.tokens?.idToken?.payload.exp,
            },
          },
        } as CognitoUserSessionInterface;
        if (cb) cb(undefined, cognitoUserSession);
      } else {
        if (cb) cb(Error("No data returned from fetchAuthSession()"));
      }
    })
    .catch((err) => {
      if (cb) cb(err);
    });
}

enum IdleTimerActions {
  START = "start",
  RESET = "reset",
  PAUSE = "pause",
  RESUME = "resume",
  START_RESUME_OR_RESET = "start, resume, or reset",
}

export function useProvideAuth() {
  const [user, setUser] = useState<UserInterface | null>(null);
  const [redirection, setRedirection] = useState<Location | undefined>(
    undefined
  );
  const [idleTimerAction, triggerUpdateIdleTimerAction] = useState<
    IdleTimerActions | undefined
  >(undefined);
  const navigate = useNavigate();

  const redirectUserToDesiredPage = () => {
    let lastVisitedPage = sessionStorage.getItem("lastVisitedPage");
    navigate(lastVisitedPage || "/", { replace: true });
  };

  const handleUserAuthStatusChange = useCallback(
    (user: UserInterface | null) => {
      if (user) {
        triggerUpdateIdleTimerAction(IdleTimerActions.START_RESUME_OR_RESET);
      } else {
        triggerUpdateIdleTimerAction(IdleTimerActions.PAUSE);
      }
      setUser(user);
    },
    [setUser]
  );

  const handleSignout = useCallback(() => {
    handleUserAuthStatusChange(null);
    inMemoryToken = undefined;
    sessionStorage.removeItem("lastVisitedPage");
  }, [handleUserAuthStatusChange]);

  let { start, reset, pause, resume, isIdle, getLastIdleTime } = useIdleTimer({
    timeout: Number(process.env.REACT_APP_AUTOMATIC_SESSION_TIMEOUT),
    onIdle: () => triggerUpdateIdleTimerAction(IdleTimerActions.PAUSE),
    onAction: () => triggerUpdateIdleTimerAction(IdleTimerActions.RESET),
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    startOnMount: false,
    startManually: true,
    stopOnIdle: false,
    passive: true,
    capture: true,
    crossTab: false,
  });

  useEffect(() => {
    switch (idleTimerAction) {
      case IdleTimerActions.PAUSE:
        pause();
        triggerUpdateIdleTimerAction(undefined);
        handleSignout();
        break;
      case IdleTimerActions.RESET:
        reset();
        triggerUpdateIdleTimerAction(undefined);
        break;
      case IdleTimerActions.RESUME:
        resume();
        triggerUpdateIdleTimerAction(undefined);
        break;
      case IdleTimerActions.START:
        start();
        triggerUpdateIdleTimerAction(undefined);
        break;
      case IdleTimerActions.START_RESUME_OR_RESET:
        const userIsIdle = isIdle();
        const lastIdleTime = getLastIdleTime();
        if (userIsIdle) {
          if (lastIdleTime) {
            resume();
          } else {
            start();
          }
        } else {
          reset();
        }
        triggerUpdateIdleTimerAction(undefined);
        break;
      default:
    }
  }, [
    idleTimerAction,
    pause,
    reset,
    resume,
    start,
    isIdle,
    getLastIdleTime,
    handleSignout,
  ]);

  useEffect(() => {
    Hub.listen(
      "auth",
      (data) => {
        const { payload } = data;
        switch (payload.event) {
          case "signedIn":
            handleCognitoHostedUIEvent();
            break;
          case "signInWithRedirect":
            break;
          case "signedOut":
            handleSignout();
            break;
          case "tokenRefresh":
            refreshToken();
            break;
          case "tokenRefresh_failure":
          case "signInWithRedirect_failure":
          default:
            break;
        }
      },
      "authEventListener"
    );

    handleCognitoHostedUIEvent();
    return hubListenerCancel();
    /* eslint-disable-next-line */
  }, []);

  const hubListenerCancel = Hub.listen("auth", () => {});

  async function handleCognitoHostedUIEvent() {
    const cognitoUserSession = await getUserSession();

    if (cognitoUserSession) {
      const { idToken } = cognitoUserSession;
      if (idToken) {
        handleUserAuthStatusChange({
          id: idToken.payload["cognito:username"],
          exp: idToken.payload.exp,
          name: idToken.payload["cognito:username"],
        });
      }
      redirectUserToDesiredPage();
    }
  }

  return {
    user,
    redirection,
    setRedirection,
    signin() {
      signInWithRedirect({
        provider: {
          custom: "DormakabaID",
        },
      });
    },
    signout() {
      signOut();
    },
  };
}
