import { useEffect, useRef, useState } from "react";
import {
  IconButton,
  Avatar,
  ClickAwayListener,
  Grow,
  Paper,
  MenuItem,
  MenuList,
  ListItemIcon,
  Popper,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useAuth } from "../../contexts/AuthProvider";
import { Ico14049 as ExitToAppIcon } from "@porthos-digitalbilling/frontend-dormakaba-icons";

import { useNavigate, useLocation } from "react-router-dom";

enum MenuItems {
  MY_DORMAKABA_ACCOUNT = "myDormakabaAccount",
  LOGOUT = "logout",
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    "& a": {
      textDecoration: "none",
      color: theme.palette.text.primary,
      "&:visited": {
        color: theme.palette.text.primary,
      },
    },
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: "0.8rem",
  },
}));

function LetterAvatar({ initials }: { initials: string }) {
  const classes = useStyles();
  return (
    <div>
      <Avatar
        classes={{
          root: classes.avatar,
        }}
      >
        {initials}
      </Avatar>
    </div>
  );
}

const MENULIST_ID = "user-session-menu-list";

const UserSessionMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { user, setRedirection } = useAuth();
  const allInitials = (user!.name?.split(" ") || []).map((namePart) =>
    namePart && namePart[0] ? namePart[0].toUpperCase() : ""
  );
  const initials =
    allInitials.length >= 2
      ? [allInitials[0], allInitials[allInitials.length - 1]].join("")
      : allInitials.join("");

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement> & {
      target: { attributes: { value?: { value: MenuItems } } };
    }
  ) => {
    const selection = event.target.attributes.value?.value;

    if (selection && selection === MenuItems.LOGOUT && setRedirection) {
      setRedirection(location);
      navigate("/logout");
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const logout = (
    <MenuItem
      key={MenuItems.LOGOUT}
      value={MenuItems.LOGOUT}
      onClick={handleMenuItemClick}
      dense
    >
      <ListItemIcon>
        <ExitToAppIcon fontSize="30px" />
      </ListItemIcon>
      Logout
    </MenuItem>
  );

  const menuItems = [logout];

  return (
    <div className={classes.root}>
      <div>
        <IconButton
          aria-label="avatar"
          ref={anchorRef}
          aria-controls={open ? MENULIST_ID : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size="small"
        >
          <LetterAvatar initials={initials} />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id={MENULIST_ID}
                    onKeyDown={handleListKeyDown}
                  >
                    {menuItems}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

export default UserSessionMenu;
