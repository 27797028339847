import { FC, ReactNode } from "react";
import { onError } from "@apollo/client/link/error";
import { ServerError } from "@apollo/client/link/utils";
import { ApolloLink } from "@apollo/client/link/core/ApolloLink";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import { refreshToken, inMemoryToken } from "../AuthProvider";
import { promiseToObservable, updateAuthHeader } from "./utils";
import { ListAccountsQueryResponse } from "../../api/queries/getLinkedAccounts";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (process.env.REACT_APP_ENV !== "prod") {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
});

const unauthenticatedErrorLink = onError(
  ({ networkError, operation, forward }) => {
    if (
      networkError &&
      networkError.name === "ServerError" &&
      (networkError as ServerError).statusCode !== undefined &&
      (networkError as ServerError).statusCode === 401
    ) {
      /**
       *  Refresh accessToken and set inMemoryToken
       */
      return promiseToObservable(
        new Promise((resolve, reject) =>
          refreshToken((err: any, cognitoUser?: any) => {
            if (err) {
              console.error("Refreshing token failed");
              reject(err);
            }
            resolve(cognitoUser?.idToken.jwtToken);
          })
        )
      ).flatMap(() => forward(operation));
    }
  }
);

const setAuthorizationLink = setContext((request, previousContext) => {
  /**
   * if you have a cached value return immediately
   */
  if (inMemoryToken) return updateAuthHeader(previousContext, inMemoryToken);

  return refreshToken((err: any, cognitoUser: any) => {
    return updateAuthHeader(inMemoryToken);
  });
});

const uri =
  process.env.REACT_APP_ENV === "qa"
    ? process.env.REACT_APP_GRAPHQL_API_ENDPOINT_QA
    : process.env.REACT_APP_ENV === "int"
    ? process.env.REACT_APP_GRAPHQL_API_ENDPOINT_QA
    : process.env.REACT_APP_ENV === "prod"
    ? process.env.REACT_APP_GRAPHQL_API_ENDPOINT_PROD
    : process.env.REACT_APP_GRAPHQL_API_ENDPOINT_DEV;

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    ApolloLink.from([
      errorLink,
      unauthenticatedErrorLink,
      setAuthorizationLink,
    ]),
    new HttpLink({
      uri: uri,
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          listAppAccounts: {
            keyArgs: false,
            merge(
              existing: ListAccountsQueryResponse,
              incoming: ListAccountsQueryResponse
            ) {
              if (!existing) {
                return incoming;
              } else {
                const mergedResult: ListAccountsQueryResponse = {
                  items: [...existing.items, ...incoming.items],
                  nextToken: incoming.nextToken,
                };
                return mergedResult;
              }
            },
          },
        },
      },
    },
  }),
});

interface ApolloClientProviderProps {
  children: ReactNode;
}

export const ApolloClientProvider: FC<ApolloClientProviderProps> = ({
  children,
}) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
