import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { DEFAULT_REDIRECTION } from "../../data/constants";

const RedirectLoggedInUser: FC = () => {
  const { redirection } = useAuth();
  if (redirection?.pathname && redirection.pathname !== "/login") {
    return <Navigate replace to={redirection} />;
  }
  return (
    <Navigate
      replace
      to={
        process.env.REACT_APP_REACT_APP_DEFAULT_REDIRECTION ||
        DEFAULT_REDIRECTION
      }
    />
  );
};

export default RedirectLoggedInUser;
