import { FC } from "react";
import { Outlet } from "react-router-dom";
import CommonLayout from "../../components/Layouts/CommonLayout/CommonLayout";

interface Props {}

const CommonLayoutPages: FC<Props> = () => {
  return (
    <CommonLayout>
      <Outlet />
    </CommonLayout>
  );
};

export default CommonLayoutPages;
