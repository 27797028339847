import { Suspense } from "react";
import Loader from "../../components/Loader/Loader";
import { Theme, createTheme } from "@mui/material/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { FC, ReactNode } from "react";
import "typeface-poppins";
import "typeface-roboto";
import GlobalCssPriority from "./GlobalCssPriority";
import { theme as dormakabaTheme } from "@porthos-digitalbilling/frontend-material-ui-theme";
import { appThemeOptions } from "./theme";

interface StylesAndThemeProviderProps {
  children: ReactNode;
}

export const StylesAndThemeProvider: FC<StylesAndThemeProviderProps> = ({
  children,
}) => {
  const theme: Theme = createTheme(dormakabaTheme, { ...appThemeOptions });
  return (
    <Suspense fallback={Loader}>
      <GlobalCssPriority>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </GlobalCssPriority>
    </Suspense>
  );
};
