import { FC } from "react";
import { useRoutes } from "react-router-dom";
import Home from "../../pages/LazyHome";
import GenericNotFound from "../../pages/LazyGenericNotFound";
import MessageHandler from "../../pages/MessageHandler";
import Login from "../../pages/LazyLogin";
import CommonLayoutPages from "./CommonLayoutPages";
import RestrictedRoute from "./RestrictedRoute";
import PrivateRoute from "./PrivateRoute";
import Logout from "../../components/Logout/Logout";
import LinkExistingAccount from "../../pages/LazyLinkExistingAccount";
import ViewLinkedAccounts from "../../pages/LazyViewLinkedAccounts";

export const routes = [
  {
    path: "/login",
    element: (
      <RestrictedRoute>
        <Login />
      </RestrictedRoute>
    ),
  },
  {
    element: <CommonLayoutPages />,
    children: [
      {
        path: "/",
        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        ),
      },
      {
        path: "/linkExistingAccount",
        element: (
          <PrivateRoute>
            <LinkExistingAccount />
          </PrivateRoute>
        ),
      },
      {
        path: "/linkedAccounts",
        element: (
          <PrivateRoute>
            <ViewLinkedAccounts />
          </PrivateRoute>
        ),
      },
      {
        path: "/logout",
        element: (
          <PrivateRoute>
            <Logout />
          </PrivateRoute>
        ),
      },
    ],
  },

  { path: "*", element: <GenericNotFound /> },
  { path: "/messageHandler", element: <MessageHandler /> },
];

export const Routes: FC = () => {
  let element = useRoutes(routes);
  return <>{element}</>;
};

export default Routes;
