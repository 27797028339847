import ratePlan from "../interfaces/ratePlan";

export const DEFAULT_REDIRECTION = "/";

export const ratePlans_Prod: ratePlan[] = [
  {
    key: 1,
    ratePlanOracode: {
      value: [
        "8a28e22681b545370181ca16550f6526",
        "8a28eb6881b5453a0181ca2fdeea00da",
      ],
      label: "Annual fee - US/CAN",
    },
    ratePlanOCD: {
      value: [
        "8a28a3bd8ecbca2d018ecd97a7024dea",
        "8a28ad968ecbca2e018ecd9a4b181683",
      ],
      label: "Annual fee - Common Doors - US/CAN",
    },
    ratePlanOUD: {
      value: [
        "8a28a3bd8ff0d33b018ff284a37d4c88",
        "8a28ad968ff0b32d018ff289df50726e",
      ],
      label: "Annual fee - OUD - US/CAN",
    },
    frequency: "annual",
  },
  {
    key: 2,
    ratePlanOracode: {
      value: [
        "8a28b38181b52e6c0181ca17389153be",
        "8a28e19f81b545390181ca2e52442982",
      ],
      label: "Quarterly fee - US/CAN",
    },
    ratePlanOCD: {
      value: [
        "8a28c43c8ff0d345018ff284a37d4c87",
        "8a28b66d8ff0b32d018ff286893132c2",
      ],
      label: "Quarterly fee - Common Doors - US/CAN",
    },
    ratePlanOUD: {
      value: [
        "8a28c43c8ff0d345018ff284a37d4c89",
        "8a28b66d8ff0b32d018ff286893132c3",
      ],
      label: "Quarterly fee - OUD - US/CAN",
    },
    frequency: "quarterly",
  },
  {
    key: 3,
    ratePlanOracode: {
      value: [
        "8a28b38b81b52e6a0181ca17b92b371c",
        "8a28b38181b52e6c0181ca2c895b5ce5",
      ],
      label: "Monthly fee - US/CAN",
    },
    ratePlanOCD: {
      value: [
        "8a28ec878ff0d33b018ff288305971ab",
        "8a28a6a78ff0b32d018ff289df50726d",
      ],
      label: "Monthly fee - Common Doors - US/CAN",
    },
    ratePlanOUD: {
      value: [
        "8a28ec878ff0d33b018ff288305971ac",
        "8a28a6a78ff0b32d018ff289df50726e",
      ],
      label: "Monthly fee - OUD - US/CAN",
    },
    frequency: "monthly",
  },
  {
    key: 4,
    ratePlanOracode: {
      value: [
        "8a2898c77fd4d533017ff3e8f77431d0",
        "8a28e22681b545370181ca3456eb73e3",
      ],
      label: "Annual fee - abroad (Non US/CAN)",
    },
    ratePlanOCD: {
      value: [
        "8a28a3bd8ecbca2d018ecd97a7024dea",
        "8a28ad968ecbca2e018ecd9a4b181683",
      ],
      label: "Annual fee - Common Doors - Abroad",
    },
    ratePlanOUD: {
      value: [
        "8a28a3bd8ff0d33b018ff284a37d4c90",
        "8a28ad968ff0b32d018ff289df50726f",
      ],
      label: "Annual fee - OUD - Abroad",
    },
    frequency: "annual",
  },
  {
    key: 5,
    ratePlanOracode: {
      value: [
        "8a28a12c7fd4d540017ff3e835d73fae",
        "8a28c77281b545330181ca335b307e77",
      ],
      label: "Quarterly fee - abroad (Non US/CAN)",
    },
    ratePlanOCD: {
      value: [
        "8a28c43c8ff0d345018ff284a37d4c87",
        "8a28b66d8ff0b32d018ff286893132c2",
      ],
      label: "Quarterly fee - Common Doors - Abroad",
    },
    ratePlanOUD: {
      value: [
        "8a28c43c8ff0d345018ff284a37d4c91",
        "8a28b66d8ff0b32d018ff286893132c4",
      ],
      label: "Quarterly fee - OUD - Abroad",
    },
    frequency: "quarterly",
  },
  {
    key: 6,
    ratePlanOracode: {
      value: [
        "8a28fadf7fd4ea27017ff3e746c367a0",
        "8a28d24c81b545370181ca31262b450f",
      ],
      label: "Monthly fee - abroad (Non US/CAN)",
    },
    ratePlanOCD: {
      value: [
        "8a28ec878ff0d33b018ff288305971ab",
        "8a28a6a78ff0b32d018ff289df50726d",
      ],
      label: "Monthly fee - Common Doors - Abroad",
    },
    ratePlanOUD: {
      value: [
        "8a28ec878ff0d33b018ff288305971ad",
        "8a28a6a78ff0b32d018ff289df50726f",
      ],
      label: "Monthly fee - OUD - Abroad",
    },
    frequency: "monthly",
  },
  {
    key: 7,
    ratePlanOracode: {
      value: [
        "8a28cb7490e30c01019108ed75231090",
        "8a289d1990e2f572019108f2bba457bb",
      ],
      label: "Monthly fee (EOM)",
    },
    ratePlanOCD: {
      value: [
        "8a28ec878ff0d33b018ff288305971ab",
        "8a28a6a78ff0b32d018ff289df50726d",
      ],
      label: "Monthly fee - Common Doors - US/CAN",
    },
    ratePlanOUD: {
      value: [
        "8a28ec878ff0d33b018ff288305971ac",
        "8a28a6a78ff0b32d018ff289df50726e",
      ],
      label: "Monthly fee - OUD - US/CAN",
    },
    frequency: "monthly",
  },
];

export const ratePlans_Dev: ratePlan[] = [
  {
    key: 1,
    ratePlanOracode: {
      value: [
        "8adc8ea17dd702af017ddda1fcd1786a",
        "8adcddea7dd7110d017ddda7be6b203d",
      ],
      label: "Annual fee - US/CAN",
    },
    ratePlanOCD: {
      value: [
        "8adcad9588dd7ced0188e39c1af3142b",
        "8adcdaf288dd8b980188e39d20075e95",
      ],
      label: "Annual fee - Common Doors - US/CAN",
    },
    ratePlanOUD: {
      value: [
        "8adc8ea17dd702af017ddda1fcd1786b",
        "8adcddea7dd7110d017ddda7be6b203e",
      ],
      label: "Annual fee - OUD - US/CAN",
    },
    frequency: "annual",
  },
  {
    key: 2,
    ratePlanOracode: {
      value: [
        "8adccb057dd71103017dddaa3e8d7ec5",
        "8adca41e7dd702a4017dddaba0ca7f1f",
      ],
      label: "Quarterly fee - US/CAN",
    },
    ratePlanOCD: {
      value: [
        "8adcfb3188dd8b980188e39a942b7061",
        "8adcdaf288dd8b980188e39b80b85d52",
      ],
      label: "Quarterly fee - Common Doors - US/CAN",
    },
    ratePlanOUD: {
      value: [
        "8adccb057dd71103017dddaa3e8d7ec6",
        "8adca41e7dd702a4017dddaba0ca7f1g",
      ],
      label: "Quarterly fee - OUD - US/CAN",
    },
    frequency: "quarterly",
  },
  {
    key: 3,
    ratePlanOracode: {
      value: [
        "8adc94ed7dd702aa017dddac11ad16b6",
        "8adccb057dd71103017dddad52b20001",
      ],
      label: "Monthly fee - US/CAN",
    },
    ratePlanOCD: {
      value: [
        "8adcfb3188dd8b980188e394ae796dff",
        "8adc9a5788dd7ce80188e39643d03223",
      ],
      label: "Monthly fee - Common Doors - US/CAN",
    },
    ratePlanOUD: {
      value: [
        "8adc94ed7dd702aa017dddac11ad16b7",
        "8adccb057dd71103017dddad52b20002",
      ],
      label: "Monthly fee - OUD - US/CAN",
    },
    frequency: "monthly",
  },
  {
    key: 4,
    ratePlanOracode: {
      value: [
        "8adcddea7dd7110d017dddafecec2333",
        "8adced107dd71102017dddb1183b1f7d",
      ],
      label: "Annual fee - VAT",
    },
    ratePlanOCD: {
      value: [
        "8adcc1d188dd8b930188e39d80767d8e",
        "8adcdaf288dd8b980188e39e6f5a5efc",
      ],
      label: "Annual fee - Common Doors - Abroad",
    },
    ratePlanOUD: {
      value: [
        "8adcddea7dd7110d017dddafecec2334",
        "8adced107dd71102017dddb1183b1f7e",
      ],
      label: "Annual fee - OUD - VAT",
    },
    frequency: "annual",
  },
  {
    key: 5,
    ratePlanOracode: {
      value: [
        "8adced107dd71102017dddb1b2511f9a",
        "8adcddea7dd7110d017dddb2bfef241f",
      ],
      label: "Quarterly fee - VAT",
    },
    ratePlanOCD: {
      value: [
        "8adcc1d188dd8b930188e39895547c06",
        "8adcad9588dd7ced0188e39a4165131e",
      ],
      label: "Quarterly fee - Common Doors - Abroad",
    },
    ratePlanOUD: {
      value: [
        "8adced107dd71102017dddb1b2511f9b",
        "8adcddea7dd7110d017dddb2bfef2420",
      ],
      label: "Quarterly fee - OUD - VAT",
    },
    frequency: "quarterly",
  },
  {
    key: 6,
    ratePlanOracode: {
      value: [
        "8adc8ea17dd702af017dddb53c1f7fd9",
        "8adcddea7dd7110d017dddb68d2c2532",
      ],
      label: "Monthly fee - VAT",
    },
    ratePlanOCD: {
      value: [
        "8adcdaf288dd8b980188e396c24d5b1f",
        "8adc96cf88dd7cfe0188e3981e826541",
      ],
      label: "Monthly fee - Common Doors - Abroad",
    },
    ratePlanOUD: {
      value: [
        "8adc8ea17dd702af017dddb53c1f7fda",
        "8adcddea7dd7110d017dddb68d2c2533",
      ],
      label: "Monthly fee - OUD - VAT",
    },
    frequency: "monthly",
  },
  {
    key: 7,
    ratePlanOracode: {
      value: [
        "8adcdaf288dd8b980188e396c24d5b1f",
        "8adc96cf88dd7cfe0188e3981e826541",
      ],
      label: "Monthly fee (EOM)",
    },
    ratePlanOCD: {
      value: [
        "8adcdaf288dd8b980188e396c24d5b1f",
        "8adc96cf88dd7cfe0188e3981e826541",
      ],
      label: "Monthly fee - Common Doors - Abroad",
    },
    ratePlanOUD: {
      value: [
        "8adc8ea17dd702af017dddb53c1f7fda",
        "8adcddea7dd7110d017dddb68d2c2533",
      ],
      label: "Monthly fee - OUD - VAT",
    },
    frequency: "monthly",
  },
];
