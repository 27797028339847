import { FC } from "react";
import { Box } from "@mui/material";
import { NavigationMenu } from "../NavigationMenu";

interface CommonMenuProps {}

const CommonMenu: FC<CommonMenuProps> = () => {
  return (
    <Box data-testid="CommonMenu">
      <NavigationMenu />
    </Box>
  );
};

export default CommonMenu;
