import * as React from "react";
import { Toolbar, Typography, Stack, IconButton, Theme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../Logo/Logo";
import { useAuth } from "../../contexts/AuthProvider";
import UserSessionMenu from "./UserSessionMenu";

export interface ThemeProps {
  theme: Theme;
}

export interface ToolbarCompProps {
  handleDrawerOpen?: () => void;
  open?: boolean;
}

export default function ToolbarComp(props: ToolbarCompProps) {
  const { handleDrawerOpen, open } = props;
  const { user } = useAuth();

  return (
    <Toolbar
      sx={(theme: Theme) => ({
        background: theme.palette.common.white,
        [theme.breakpoints.up("xs")]: {
          minHeight: 48,
          height: 48,
        },
        paddingLeft: theme.spacing(3),
      })}
    >
      {user && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon
            sx={(theme: Theme) => ({
              color: theme.palette.common.black,
            })}
          />
        </IconButton>
      )}
      <Logo style={{ width: 172 }} />

      {!user ? (
        <Typography
          component="h1"
          variant="h6"
          noWrap
          data-testid="logged-out-welcome-message"
          sx={(theme: Theme) => ({
            color: theme.palette.primary.main,
            fontSize: 20,
            marginLeft: 3,
            fontWeight: 500,
          })}
        >
          Oracode Admin Tool
        </Typography>
      ) : (
        <Typography
          component="h1"
          variant="h6"
          noWrap
          data-testid="logged-in-welcome-message"
          sx={(theme: Theme) => ({
            color: theme.palette.text.primary,
            opacity: 0.38,
            fontSize: 14,
            marginLeft: 3,
          })}
        >
          Welcome
          {user?.name ? `, ${user.name}` : ""}
        </Typography>
      )}
      <Stack
        alignItems="center"
        direction="row"
        sx={{
          marginLeft: "auto",
          marginRight: 3,
        }}
      >
        {user && <UserSessionMenu />}
      </Stack>
    </Toolbar>
  );
}
