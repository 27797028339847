import { FC } from "react";
import {
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Collapse,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CommonMenuItemT, MenuItemProps } from "./config";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  link: {
    textDecoration: "none",
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ":visited": {
      color: theme.palette.text.secondary,
    },
    ":active": {
      color: theme.palette.primary.main,
    },
  },
  menuItem: {
    borderLeftColor: theme.palette.background.paper,
    borderLeftWidth: theme.spacing(0.75),
    borderLeftStyle: "solid",
  },
  activeMenuItem: {
    borderLeftColor: theme.palette.primary.main,
  },
  listItemText: {
    paddingRight: theme.spacing(2),
    "&.MuiTypography-root,.MuiListItemText-primary": {
      fontSize: "0.9rem",
    },
    "&.MuiTypography-root,.MuiListItemText-secondary": {
      fontSize: "0.8rem",
    },
  },
  activeLink: {
    opacity: 1,
    color: "#003594",
  },
}));

export const NavigationMenuItem: FC<MenuItemProps> = ({
  translationKey,
  href,
  open,
  toggleOpen,
  location,
  icon: Icon,
  subMenuItems,
  nestingLevel,
  routeMatchPath,
}) => {
  const classes = useStyles();
  const isActive =
    href === "/"
      ? location.pathname === href
      : location.pathname.includes(routeMatchPath || href);

  const menuItemClasses = clsx({
    [classes.menuItem]: true,
    [classes.activeMenuItem]: isActive,
  });
  const maybeExpandMoreOrLess = !subMenuItems ? null : open === href ? (
    <ExpandLess sx={[isActive && { color: "primary.main" }]} />
  ) : (
    <ExpandMore sx={[isActive && { color: "primary.main" }]} />
  );

  return (
    <>
      <ListItem
        key={`navigation-menu-item-${href}`}
        data-testid={`navigation-menu-item-${href}`}
        button
        {...(subMenuItems &&
          subMenuItems.length > 0 && { onClick: () => toggleOpen!(href) })}
        className={menuItemClasses}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <NavLink
          {...(subMenuItems && { onClick: () => toggleOpen!(href) })}
          className={classes.link}
          to={href}
          style={{ color: "inherit" }}
        >
          <ListItemIcon
            sx={[
              { minWidth: 6, paddingRight: 2, color: "text.secondary" },
              isActive && { color: "primary.main" },
            ]}
          >
            {Icon && <Icon fontSize="1.5rem" />}
          </ListItemIcon>

          {nestingLevel ? (
            <ListItemText
              secondary={translationKey}
              className={classes.listItemText}
              secondaryTypographyProps={{
                sx: [
                  { paddingLeft: 5 },
                  isActive && { opacity: 1, color: "primary.main" },
                ],
              }}
            />
          ) : (
            <ListItemText
              primary={translationKey}
              className={classes.listItemText}
              primaryTypographyProps={{
                sx: [isActive && { opacity: 1, color: "primary.main" }],
              }}
            />
          )}
        </NavLink>
        {maybeExpandMoreOrLess}
      </ListItem>
      {subMenuItems && (
        <Collapse in={open === href} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subMenuItems.map((child: CommonMenuItemT) => (
              <NavigationMenuItem
                key={`nested-${(nestingLevel || 0) + 1}-nav-item-${child.href}`}
                {...child}
                nestingLevel={(nestingLevel || 0) + 1}
                location={location}
                open={open}
                toggleOpen={toggleOpen}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default NavigationMenuItem;
