import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  maxHeight: theme.spacing(6),
  height: theme.spacing(6),
  // necessary for content to be below app bar
}));

export default function DrawerHeaderWithCloseButton(props: any) {
  const theme = useTheme();
  const { handleDrawerClose } = props;

  return (
    <DrawerHeader>
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </DrawerHeader>
  );
}
