import { FC, useState } from "react";
import { List } from "@mui/material";
import { menuItems } from "./config";
import { NavigationMenuItem } from "./NavigationMenuItem";
import { useLocation } from "react-router-dom";
import { DrawerWidth } from "../../config";

export const NavigationMenu: FC = () => {
  const [open, setOpen] = useState<string>("");
  const toggleOpen = (location: string) =>
    open === location ? setOpen("") : setOpen(location);
  const location = useLocation();

  return (
    <List
      component="nav"
      aria-labelledby="navigation-menu"
      sx={{
        width: "100%",
        maxWidth: DrawerWidth.ICON_AND_TEXT,
        backgroundColor: "background.paper",
      }}
    >
      {menuItems.map((item) => (
        <NavigationMenuItem
          {...item}
          key={`navigation-item-${item.href}`}
          location={location}
          open={open}
          toggleOpen={toggleOpen}
        />
      ))}
    </List>
  );
};

export default NavigationMenu;
