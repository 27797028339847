import { FC, ReactNode, Suspense } from "react";
import Loader from "../../components/Loader/Loader";
import { useAuth } from "../AuthProvider";
import SimpleLayout from "../../components/Layouts/SimpleLayout/SimpleLayout";
import RedirectLoggedInUser from "./RedirectLoggedInUser";

interface Props {
  children: ReactNode;
}

const RestrictedRoute: FC<Props> = ({ children }) => {
  const { user } = useAuth();
  return (
    <>
      {user ? (
        <RedirectLoggedInUser />
      ) : (
        <SimpleLayout>
          <Suspense fallback={<Loader />}>{children}</Suspense>
        </SimpleLayout>
      )}
    </>
  );
};

export default RestrictedRoute;
