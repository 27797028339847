import { FC, ReactNode } from "react";
import { StyledEngineProvider } from "@mui/material/styles";

interface Props {
  children: ReactNode;
}

export const GlobalCssPriority: FC<Props> = ({ children }) => {
  return (
    <>
      {/* Inject emotion before JSS */}
      <StyledEngineProvider injectFirst>
        {/* Your component tree. Now you can override MUI's styles. */}
        {children}
      </StyledEngineProvider>
    </>
  );
};

export default GlobalCssPriority;
