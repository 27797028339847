import { useEffect } from "react";
import { useAuth } from "../../contexts/AuthProvider";

const Logout = () => {
  const { signout } = useAuth();
  useEffect(() => {
    signout();
    /* eslint-disable-next-line */
  }, []);
  return null;
};

export default Logout;
