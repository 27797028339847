/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

type UseDialogShowReturnType = {
  show: boolean;
  setShow: (value: boolean) => void;
  onHide: () => void;
};

const useDialogShow = (): UseDialogShowReturnType => {
  const [show, setShow] = useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide,
  };
};

type DialogContextType = {
  showConfirmation: (
    title: string,
    message: string | JSX.Element,
    isError?: boolean
  ) => Promise<boolean>;
};

type ConfirmationDialogContextProviderProps = {
  children: React.ReactNode;
};

const ConfirmationDialogContext = React.createContext<DialogContextType>(
  {} as DialogContextType
);

const ConfirmationDialogContextProvider: React.FC<
  ConfirmationDialogContextProviderProps
> = (props) => {
  const { setShow, show, onHide } = useDialogShow();
  const [content, setContent] = useState<{
    title: string;
    message: string | JSX.Element;
    isError?: boolean;
  } | null>();
  const resolver = useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const handleShow = (
    title: string,
    message: string | JSX.Element,
    isError = false
  ): Promise<boolean> => {
    setContent({
      title,
      message,
      isError,
    });
    setShow(true);
    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const dialogContext: DialogContextType = {
    showConfirmation: handleShow,
  };

  const handleOk = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    resolver.current && resolver.current(false);
    onHide();
  };

  return (
    <ConfirmationDialogContext.Provider value={dialogContext}>
      {props.children}

      {content && (
        <Dialog
          open={show}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{content.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {content.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!content.isError && <Button onClick={handleCancel}>Cancel</Button>}
            <Button onClick={handleOk} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialogContext = (): DialogContextType =>
  useContext(ConfirmationDialogContext);

export { useDialogShow, useConfirmationDialogContext };

export default ConfirmationDialogContextProvider;
