import { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ConfirmationDialogContextProvider from "../components/Dialog/dialogConfirmationContext";
import { ApolloClientProvider } from "../contexts/ApolloProvider";
import { AuthProvider } from "../contexts/AuthProvider";
import { Routes } from "../contexts/RoutingProvider/Routes";

const App: FC<{ loading?: any }> = (props) => {
  return (
    <>
      <Router>
        <AuthProvider>
          <ConfirmationDialogContextProvider>
            <ApolloClientProvider>
              <Routes />{" "}
            </ApolloClientProvider>
          </ConfirmationDialogContextProvider>
        </AuthProvider>
      </Router>
    </>
  );
};

export default App;
