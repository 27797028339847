import { FC, ReactNode, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "../../AppBar/ToolBar";
import Drawer from "../../../components/Drawer/Drawer";
import CommonGrid from "../CommonGrid";
import DrawerHeader from "../../Drawer/DrawerHeader";
import { DrawerWidth } from "../../../config";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DrawerWidth.ICON_AND_TEXT,
    width: `calc(100% - ${DrawerWidth.ICON_AND_TEXT}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface Props {
  children: ReactNode;
}

export const CommonLayoutComp: FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar handleDrawerOpen={handleDrawerOpen} open={open} />
      </AppBar>
      <Drawer open={open} handleDrawerClose={handleDrawerClose} />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <CommonGrid>{children}</CommonGrid>
      </Box>
    </Box>
  );
};

export default CommonLayoutComp;
