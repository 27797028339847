import { createContext, FC, ReactNode, useContext } from "react";
import { useProvideAuth, UserInterface } from "./useProvideAuth";
import { Location } from "react-router-dom";

interface AuthContext {
  user: UserInterface | null;
  redirection?: Location;
  setRedirection: ((location: Location | undefined) => void) | undefined;
  signin: () => void;
  signout: () => void;
}

const authContext = createContext<AuthContext>({
  user: null,
  redirection: undefined,
  setRedirection: undefined,
  signin: () => {},
  signout: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export function useAuth() {
  return useContext(authContext);
}
