import { Theme, ThemeOptions } from "@mui/material/styles";
import { theme as dormakabaTheme } from "@porthos-digitalbilling/frontend-material-ui-theme";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

/**
 * enhance material ui Theme interface with app specific theme variables
 */
declare module "@mui/material/styles" {
  interface Theme {
    drawerWidth: number;
    mainContentGutters: {
      xs: string;
      sm: string;
      lg: string;
    };
    toolbarHeight: {
      xs: number;
      sm: number;
      lg: number;
    };
    formSpacing: number;
    deleteCellWidth: string;
    feedbackStatusCellWidth: string;
  }

  interface ThemeOptions {
    drawerWidth?: number;
    mainContentGutters?: {
      xs: string;
      sm: string;
      lg: string;
    };
    toolbarHeight?: {
      xs: number;
      sm: number;
      lg: number;
    };
    formSpacing?: number;
    deleteCellWidth?: string;
    feedbackStatusCellWidth?: string;
  }
}

export enum DrawerWidth {
  ICON = 56,
  ICON_AND_TEXT = 270,
}

export const appThemeOptions: ThemeOptions = {
  drawerWidth: 260,
  mainContentGutters: {
    xs: dormakabaTheme.spacing(2),
    sm: dormakabaTheme.spacing(3),
    lg: dormakabaTheme.spacing(5),
  },
  toolbarHeight: {
    xs: 48,
    sm: 56,
    lg: 80,
  },
  formSpacing: 3,
  deleteCellWidth: `calc(48px + ${dormakabaTheme.spacing(4)})`,
  feedbackStatusCellWidth: `calc(100px + ${dormakabaTheme.spacing(4)})`,
  palette: {
    // warning color is defined here instead of theme lib
    // because it's different from the dormakaba standard
    warning: {
      light: "#ffdd00",
      main: "#ffbb00",
      dark: "#783b21",
      // background: '#ffeda2',
    },
    background: {
      default: dormakabaTheme.palette.grey[50],
    },
  },
  components: {
    MuiCssBaseline: {
      // needs to be written in CSS string template, otherwise fonts from the dormakaba theme
      // won't be loaded.
      styleOverrides: `
        ${dormakabaTheme!.components!.MuiCssBaseline!.styleOverrides}
        html,
        body,
        #root {
          height: 100%;
        }
      `,
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: dormakabaTheme.spacing(3),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: "auto",
          padding: dormakabaTheme.spacing(0, 3, 3),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: dormakabaTheme.spacing(0, 3, 3),
        },
        spacing: {
          "&> :not(:first-of-type)": {
            marginLeft: dormakabaTheme.spacing(2),
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "40px",
          color: "inherit",
          "& svg": {
            fontSize: "1.5rem",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          "&.MuiButton-containedWarning": {
            color: dormakabaTheme.palette.common.white,
            "&:hover": {
              backgroundColor: dormakabaTheme.palette.warning.dark,
            },
          },
        },
      },
    },
  },
};

export const dataGridDefaultPageSize = 10;
