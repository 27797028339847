import { FC, ReactNode, Suspense, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useAuth } from "../AuthProvider";

interface Props {
  children: ReactNode;
}

const PrivateRoute: FC<Props> = ({ children }) => {
  const { user, setRedirection } = useAuth();
  let location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/logout" && setRedirection !== undefined) {
      setRedirection(location);
    }
  }, [setRedirection, location]);
  if (!user) {
    sessionStorage.setItem("lastVisitedPage", location.pathname);

    return <Navigate replace to="/login" state={{ from: location }} />;
  }
  return <Suspense fallback={<Loader />}>{children}</Suspense>;
};

export default PrivateRoute;
