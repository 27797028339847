import logo from "../../assets/logo/dormakaba.png";

export interface LogoProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export default function Logo(props: LogoProps) {
  const { className, style, onClick } = props;

  return (
    <img
      alt="Dormakaba"
      src={logo}
      onClick={onClick}
      className={className}
      style={style}
    ></img>
  );
}
