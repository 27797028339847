import { Ico120276 as LinkIcon } from "@porthos-digitalbilling/frontend-dormakaba-icons";
import { Ico20006 as HomeIcon } from "@porthos-digitalbilling/frontend-dormakaba-icons";
import { Ico16031 as LinkedIcon } from "@porthos-digitalbilling/frontend-dormakaba-icons";

export interface CommonMenuItemT {
  translationKey: string;
  href: string;
  /**
   * Path against which the isActive flag will be calculated (instead of href), when supplied
   */
  routeMatchPath?: string;
  icon?: any;
  subMenuItems?: CommonMenuItemT[];
  nestingLevel?: number;
}

export interface MenuItemProps extends CommonMenuItemT {
  location: { pathname: string };
  open?: string;
  toggleOpen?: (item: string) => void;
}

export const menuItems: CommonMenuItemT[] = [
  {
    translationKey: "Home",
    href: "/",
    routeMatchPath: "/",
    icon: HomeIcon,
  },
  {
    translationKey: "Link Existing Account",
    href: "/linkExistingAccount",
    routeMatchPath: "/linkExistingAccount",
    icon: LinkIcon,
  },
  {
    translationKey: "View Linked Accounts",
    href: "/linkedAccounts",
    routeMatchPath: "/linkedAccounts",
    icon: LinkedIcon,
  },
];
