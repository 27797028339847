import React, { FC, ReactNode } from "react";
import { Container, Grid } from "@mui/material";

interface CommonGridProps {
  children: ReactNode;
}

const CommonGrid: FC<CommonGridProps> = (props: CommonGridProps) => {
  return (
    <Container
      maxWidth={false}
      sx={(theme) => ({
        display: "flex",
        [theme.breakpoints.up("xs")]: {
          paddingTop: 2,
          paddingLeft: theme.mainContentGutters.xs,
          paddingRight: theme.mainContentGutters.xs,
        },
        [theme.breakpoints.up("sm")]: {
          paddingTop: 2,
          paddingLeft: theme.mainContentGutters.sm,
          paddingRight: theme.mainContentGutters.sm,
        },
        [theme.breakpoints.up("lg")]: {
          paddingTop: 2,
          paddingLeft: theme.mainContentGutters.lg,
          paddingRight: theme.mainContentGutters.lg,
        },
      })}
      data-testid="CommonGrid"
    >
      <Grid
        item
        xs={12}
        sx={{
          flex: 1,
        }}
      >
        {props.children}
      </Grid>
    </Container>
  );
};

export default CommonGrid;
